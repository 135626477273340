import { trafficLightColorDecider } from "@/shared/util/chart-helper";
import i18n from "@/i18n";

// Mean Chart
export function MeanChart() {
  this.chartOptions = {
    chart: {
      height: 300,
      type: "radialBar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: "#111",
            fontSize: "30px",
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function ({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    }
    // responsive: [
    //   {
    //     breakpoint: 1441,
    //     options: {
    //       chart: {
    //         height: 200
    //       }
    //     }
    //   }
    // ]
  };
}

// RScore Chart
export function RScoreChart() {
  this.chartOptions = {
    chart: {
      height: 200,
      type: "radialBar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -5,
            show: true,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function (val) {
              return val;
            },
            color: "#111",
            fontSize: "15px",
            offsetY: 4,
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function ({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    },
    // responsive: [
    //   {
    //     breakpoint: 3000,
    //     options: {
    //       chart: {
    //         height: 250
    //       }
    //     }
    //   }
    // ],
    labels: ["R-Score"]
  };
}

// TS Chart
export function TimeScoreChart() {
  this.chartOptions = {
    chart: {
      height: 150,
      type: "radialBar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -5,
            show: true,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function (val) {
              return val;
            },
            color: "#111",
            fontSize: "15px",
            offsetY: 4,
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function ({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    },
    responsive: [
      {
        breakpoint: 3000,
        options: {
          chart: {
            height: 200
          }
        }
      }
    ],
    labels: ["Time-Score"]
  };
}

// CS Chart
export function CScoreChart() {
  this.chartOptions = {
    chart: {
      height: 150,
      type: "radialBar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -5,
            show: true,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function (val) {
              return val;
            },
            color: "#111",
            fontSize: "15px",
            offsetY: 4,
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function ({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    },
    responsive: [
      {
        breakpoint: 3000,
        options: {
          chart: {
            height: 200
          }
        }
      }
    ],
    labels: ["C-Score"]
  };
}

// PS Chart
export function PScoreChart() {
  this.chartOptions = {
    chart: {
      height: 150,
      type: "radialBar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -5,
            show: true,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function (val) {
              return val;
            },
            color: "#111",
            fontSize: "15px",
            offsetY: 4,
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function ({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    },
    responsive: [
      {
        breakpoint: 3000,
        options: {
          chart: {
            height: 200
          }
        }
      }
    ],
    labels: ["P-Score"]
  };
}

// Item Battery Score Chart
export function ItemBatteryScoreChart() {
  this.chartOptions = {
    chart: {
      height: 150,
      type: "radialBar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -5,
            show: true,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function (val) {
              return val;
            },
            color: "#111",
            fontSize: "15px",
            offsetY: 4,
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function ({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    },
    responsive: [
      {
        breakpoint: 3000,
        options: {
          chart: {
            height: 200
          }
        }
      }
    ],
    labels: ["GQS"]
  };
}

// Time Score Performance Chart
export function TimeScorePerformanceChart() {
  this.chartOptions = {
    chart: {
      id: "TimeScorePerformance",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: []
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        }
      }
    },
    colors: [
      function ({ value }) {
        return trafficLightColorDecider(value);
      }
    ]
  };
}

// Open Ended Score Chart
export function OpenEndedScoreChart() {
  this.chartOptions = {
    chart: {
      height: 150,
      type: "radialBar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: "65%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.2
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -5,
            show: true,
            color: "#005181",
            fontSize: "15px"
          },
          value: {
            formatter: function (val) {
              return val;
            },
            color: "#111",
            fontSize: "15px",
            offsetY: 4,
            show: true
          }
        }
      }
    },
    fill: {
      type: "solid"
    },
    colors: [
      function ({ value }) {
        return trafficLightColorDecider(value);
      }
    ],
    stroke: {
      lineCap: "round"
    },
    responsive: [
      {
        breakpoint: 3000,
        options: {
          chart: {
            height: 200
          }
        }
      }
    ],
    labels: ["OES"]
  };
}

// Median Chart
export function MedianChart() {
  this.chartOptions = {
    chart: {
      id: "Median",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: []
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        }
      }
    },
    colors: ["#005181"]
  };
}

// Distribution Cahrt
export function DistributionChart() {
  this.chartOptions = {
    chart: {
      id: "Distribution",
      type: "line",
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [
        "0-9",
        "10-19",
        "20-29",
        "30-39",
        "40-49",
        "50-59",
        "60-69",
        "70-79",
        "80-89",
        "90-100"
      ]
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        }
      }
    },
    colors: ["#005181"],
    stroke: {
      curve: "smooth",
      width: 3
    }
  };
}

// Traffic Light Chart
export function TrafficLightChart() {
  this.chartOptions = {
    chart: {
      id: "TL",
      toolbar: {
        show: false
      }
    },
    labels: ["0 to < 40", "40 to < 60", "60 to < 80", "80 to 100", "No Score"],
    colors: ["#f46a6a", "#ffcc44", "#a5e79a", "#34c38f", "#adb5bd"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 280
          },
          legend: {
            show: true
          }
        }
      }
    ],
    tooltip: {
      y: {
        formatter: function (value) {
          return value.toFixed(2) + "%";
        }
      }
    }
  };
}

// Result Chart
export function ResultChart() {
  this.chartOptions = {
    chart: {
      id: "ErgebnisDiagramme",
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "flat"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"]
    },
    colors: ["#adb5bd", "#005181", "#00a181"],
    xaxis: {
      categories: ["answer 1", "answer 2", "answer 3", "answer 4"],
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: true,
        style: {
          fontSize: "12px"
        },
        formatter: (value) => {
          var len = value.length;
          return len > 10 ? value.substring(0, 20) + "..." : value;
        }
      }
    },
    grid: {
      borderColor: "#f1f1f1"
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      x: {
        formatter: (val) => {
          return val.replace(/(.{80})/g, "$1<br>");
        }
      }
    }
  };
}

// Time Score Comparison Chart
export function timeScoreComparisonChart() {
  this.chartOptions = {
    chart: {
      id: "Comparison",
      type: "bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#005181", "#adb5bd"],
    xaxis: {
      categories: []
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        }
      }
    },
    grid: {
      borderColor: "#f1f1f1"
    },
    fill: {
      opacity: 1
    }
  };
}

//Certainty Score Comparison Chart
export function CSAnswerComparisonChart() {
  this.chartOptions = {
    chart: {
      id: "Comparison",
      type: "bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#005181", "#adb5bd"],
    xaxis: {
      categories: []
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        }
      }
    },
    grid: {
      borderColor: "#f1f1f1"
    },
    fill: {
      opacity: 1
    }
  };
}

// Standard Diviation Comparison Chart
export function StdComparisonChart() {
  this.chartOptions = {
    chart: {
      height: 300,
      type: "radialBar"
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
            fontSize: "14px",
            fontWeight: 600,
            offsetY: -10
          },
          value: {
            show: true,
            fontSize: "14px",
            offsetY: 4,
            formatter: function (val) {
              return val;
            }
          }
        }
      }
    },
    stroke: {
      lineCap: "round"
    },
    colors: ["#005181", "#50a5f1"],
    labels: ["STD", "Avg STD"],
    legend: {
      show: false
    }
  };
}

// Average Prediction Chart
export function AveragePredictionChart() {
  this.chartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    annotations: {
      xaxis: [
        {
          x: 0,
          borderColor: "#005181",
          yAxisIndex: 0,
          strokeDashArray: 0,
          label: {
            text: "Projection",
            orientation: "horizontal",
            textAnchor: "start",
            style: {
              color: "#000",
              background: "#005181"
            }
          }
        },
        {
          x: 0,
          borderColor: "#777",
          yAxisIndex: 0,
          strokeDashArray: 0,
          label: {
            position: "top",
            orientation: "vertical",
            text: "Average Distribution",
            style: {
              color: "#000",
              background: "#777"
            }
          }
        }
      ]
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: true,
        style: {
          fontSize: "12px"
        }
      }
    },
    colors: ["#005181"],
    stroke: {
      curve: "smooth",
      width: 3
    }
  };
}

// IBS answer chart
export function IBSAnswerChart() {
  this.chartOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    annotations: {
      xaxis: [
        {
          x: 0,
          borderColor: "#f5f5f5",
          yAxisIndex: 0,
          strokeDashArray: 0
        }
      ]
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [],
      labels: {
        formatter: function (val) {
          return "Item " + val;
        },
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: false,
        trim: true,
        style: {
          fontSize: "12px"
        }
      }
    },
    colors: ["#005181"],
    stroke: {
      curve: "straight",
      width: 3
    }
  };
}

// IS Answer Options Chart
export function ISAnswerOptionChart() {
  this.chartOptions = {
    chart: {
      type: "bar",
      height: 440,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: ["#34c38f", "#adb5bd"],
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%"
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    yaxis: {
      min: -120,
      max: 120
    },
    tooltip: {
      shared: false,
      x: {
        formatter: function (val) {
          return val;
        }
      },
      y: {
        formatter: function (val) {
          return Math.abs(val) + "%";
        }
      }
    },
    xaxis: {
      categories: [],
      labels: {
        show: false,
        formatter: function (val) {
          return Math.abs(Math.round(val)) + "%";
        }
      }
    }
  };
}

// R-score Pie Chart
export function RScorePresentationChart() {
  this.chartOptions = {
    chart: {
      id: "RScorePresentationChart",
      toolbar: {
        show: false
      }
    },
    labels: ["OES", "TS", "GQS", "PS", "CS"],
    colors: ["#77009a", "#a5e79a", "#ffd46c", "#FF7F7F", "#90b7ff"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 280
          },
          legend: {
            show: true
          }
        }
      }
    ],
    tooltip: {
      y: {
        formatter: function (value) {
          if (Math.round(value) === value) return value + "%";
          let roundVal = Math.round(value * 10000) / 100;
          if (roundVal > 100) roundVal /= 100;
          return roundVal.toFixed(2) + "%";
        }
      }
    }
  };
}

// Quota Setting Chart
export function QuotaSettingsChart() {
  this.chartOptions = {
    chart: {
      height: 250,
      type: "bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        const goals = w.config.series[seriesIndex].data[dataPointIndex].goals;

        if (value >= goals[0].value) {
          return "#34c38f";
        } else {
          return "#adb5bd";
        }
      }
    ],
    dataLabels: {
      formatter: function (val, opt) {
        const goals =
          opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;

        if (goals && goals.length) {
          return `${val} / ${goals[0].value}`;
        }
        return val;
      }
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [
        "Actual value (> Target value)",
        "Actual value (< Target value)",
        "Target value"
      ],
      markers: {
        fillColors: ["#00E396", "#adb5bd", "#005181"]
      }
    },
    tooltip: {
      marker: {
        show: true
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          "<div>" +
          '<div style="font-size: 12px;padding: 8px;background: #f5f5f5; border-bottom: 1px solid #e9ecef">' +
          w.globals.labels[dataPointIndex] +
          "</div>" +
          '<div style="font-size: 12px;padding: 8px">' +
          "<div>" +
          "Calculated Value : " +
          series[seriesIndex][dataPointIndex] +
          "</div>" +
          '<div style="margin-top: 2px">' +
          "Target Value : " +
          w.config.series[seriesIndex].data[dataPointIndex].goals[0].value +
          "</div>" +
          "</div>" +
          "</div>"
        );
      }
    }
  };
}

// Distribution Histogram Chart
export function DistributionHistogramChart() {
  this.chartOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#005181"],
    xaxis: {
      categories: [
        "0-10",
        "10-20",
        "20-30",
        "30-40",
        "40-50",
        "50-60",
        "60-70",
        "70-80",
        "80-90",
        "90-100"
      ]
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    }
  };
}

// Performance Chart
export function PerformanceChart() {
  this.chartOptions = {
    chart: {
      id: "TimeScorePerformance",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "15%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: []
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        }
      }
    },
    colors: [
      function ({ value }) {
        return trafficLightColorDecider(value);
      }
    ]
  };
}

//OES category chart
export function OESCategoryChart() {
  this.chartOptions = {
    chart: {
      id: "OESCategoryChart",
      toolbar: {
        show: false
      }
    },
    colors: [
      "#28B463",
      "#adb5bd",
      "#959595",
      "#df2c14",
      "#fb3b1e",
      "#ff4122",
      "#ff6242",
      "#ff8164",
      "#ffa590",
      "#FFBDAE",
      "#660303"
    ],
    legend: {
      show: true,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 0
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 280
          },
          legend: {
            show: true
          }
        }
      }
    ],
    tooltip: {
      y: {
        formatter: function (value) {
          return value.toFixed(1) + "%";
        }
      }
    }
  };
}

// Data record Summary Chart
export function dataRecordSummaryChart() {
  this.chartOptions = {
  chart: {
    type: 'donut',
  },
  plotOptions: {
    pie: {
      startAngle: -90,
      endAngle: 90,
      offsetY: 0
    }
  },
  grid: {
    padding: {
      bottom: -50
    }
  },
  dataLabels: {
    enabled: true
  },
  legend: {
    show: true,
    position: 'bottom',
    // horizontalAlign: "center",
    // verticalAlign: "middle",
    floating: false,
    fontSize: "14px",
    offsetX: 0,
    offsetY: -90
  },
  labels: ["Included", "Excluded" ],
  colors: ["#34c38f", "#f46a6a"],
  tooltip: {
      y: {
        formatter: function (value) {
          return value.toFixed(2) + "%";
        }
      }
    }
}
}

// Benchmark Distribution Chart
export function BenchmarkDistributionHistoramChart() {
  this.chartOptions = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '55%'
      }
    },
    colors: ['#005181'],
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: [i18n.t("datafileResultRawTab.benchmarkWidget.yourData"), 'Benchmark'],
      markers: {
        fillColors: ['#005181', '#00a181']
      }
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + "%";
        }
      }
    }
  }
}

// Benchmark RScore Chart
export function BenchmarkRScoreChart() {
  this.chartOptions = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '14px',
          },
          value: {
            fontSize: '16px',
            formatter: function (val) {
              return parseInt(val); // Remove percentage sign and display the value as integer
            }
          }
        }
      }
    },
    colors: ['#005181', '#00a181'],
    labels: [i18n.t("datafileResultRawTab.benchmarkWidget.yourData"), 'Benchmark'],
  }
}
